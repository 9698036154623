import React, {  useState } from 'react'
import s from './products.module.css';
import { IDevice } from 'app/interfaces'
import {  AiFillCloseCircle, AiFillDelete, AiFillPlusCircle } from 'react-icons/ai';



type Props = {
    device: IDevice
    updateDev: (changeDevice: IDevice |undefined) => void
    close: () => void
}
type Props2 = {
    text2: string
    giveInput: (index: number, input: string) => void
    index: number
}
const Text: React.FC<Props> = ({ device, updateDev, close }) => {

    const [text, setText] = useState<string[]>(device.text.split(';'))
    const [urlReestr, setURL] =useState<string>(device.url)


    const giveInput = (index: number, input: string) => {
        setText(text.map((el, i) => {
            if (i === index) return input
            return el
        }))
    }



    const Text2: React.FC<Props2> = ({ text2, giveInput, index }) => {
        const [input, setInput] = useState(text2)
        const [down, setDown ] = useState<boolean>(false)
         
         
        return (
            <div 
            onMouseLeave={() => {if(down)  {giveInput(index, input)}; setDown(false)}}
            onFocus={() => setDown(true) 
            }   >
                {(index === text.length - 1) && <h3>for link</h3>}


                <textarea className={s.area} value={input} onChange={(e) => {
                    setInput(e.currentTarget.value)
                }} /> 
                <AiFillDelete onClick={() =>{text.splice(index, 1) ; setText([...text])} }/>
            </div>

        )
    }

    const textInput = text.map((el, i) => {
        return (
            <div key={'text' + i}>
                <Text2 text2={el} giveInput={giveInput} index={i} />
            </div>

        )
    })

    return (
        <div className={s.container}>
            <AiFillCloseCircle onClick={close} />
            <div>
                {textInput}
            <AiFillPlusCircle onClick={() => {setText([...text, ''])}} />
            </div>
            
            <input type="text" value={urlReestr} onChange={(e) => {setURL(e.currentTarget.value)}}/>
            <button onClick={() => { updateDev({...device, text:text.join(';'), url: urlReestr}); close() }}>SAVE</button>
        </div>
    )


};

export default Text