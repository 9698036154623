import loginReduser from './redusers/loginSlice';
import { configureStore } from '@reduxjs/toolkit'
import { loginApi } from './redusers/loginApi'
import { setupListeners } from '@reduxjs/toolkit/query';
import { userApi } from './redusers/usersAPI';
import { ordersApi } from './redusers/ordersApi';
import { filesApi } from './redusers/filesApi';
import { catalogApi } from './redusers/catalogApi';
import { deviceApi } from './redusers/deviceApi';


export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [catalogApi.reducerPath]: catalogApi.reducer,
    [deviceApi.reducerPath]: deviceApi.reducer,
    login: loginReduser
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(loginApi.middleware,
      userApi.middleware,
      ordersApi.middleware,
      filesApi.middleware,
      catalogApi.middleware,
      deviceApi.middleware
      )
  ,

})
setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch