import React, { useEffect, useState } from 'react'
import s from './products.module.css'
import { IDevice } from 'app/interfaces'
import Img from './Img';
import Text from './Text';
import UIinput from 'components/UI/UIinput';
import TechGar from './TechGar';
import Docum from './Docum';
import { useGetDevice2Mutation, useUpdateDeviceMutation } from 'app/redusers/deviceApi';
import Preloder from 'UI/Preloder/Preloder';



type Props = {
    name: string
    pattern?: string
    showPattern: boolean
    setShowPattern: (a:boolean)=> void
}

const Device: React.FC<Props> = ({ name, pattern, showPattern , setShowPattern}) => {

    const changeItem = ['img', 'fullname', 'text', 'techcharact', 'documents', 'guarant', 'producer']
    const [actPictures, setPic] = useState(0);
    const [popUp, setPopUp] = useState<boolean[]>(changeItem.map(() => false));
    const [device, setChange] = useState<IDevice>()
    const [updateDevice, dataDeviceupdate] = useUpdateDeviceMutation();
    const [deviceGet] = useGetDevice2Mutation();

    const imgBox = ["", "", ""]


    useEffect(() => {
        deviceGet(name).unwrap()
        .then((payload) => setChange(payload))


    }, [name, deviceGet])

   const updateDev = async (changeDevice: IDevice | undefined) => {
        if (changeDevice) {
            await updateDevice({ device: changeDevice, token: localStorage.getItem('tokenAdmin') })
            await deviceGet(name).unwrap()
            .then((payload) => {
             setChange(payload)})
            
        }

    }
   
    
    const onClick=  async() => {
        if (pattern && device) {
            
            
        await    deviceGet(pattern).unwrap()
        .then(payload => {
            
            setChange({...payload, _id:device._id, name:device.name, fullname:device.name, undercatalog:device.undercatalog,
            subcatalog: device.subcatalog, catalog: device.catalog})
        
        })
        .catch(error=> {console.log(error);
        })

        }
        
        
       // await updateDev(device)
        setShowPattern(false)

    }


    const buttonPop = (popUp: string) => {


        return (
            <button onClick={() => {
                let a = changeItem.map(el => {
                    if (el === popUp) return true
                    return false
                })
                setPopUp(a)
            }}>Edit</button>
        )
    }
    const textArray = device?.text.split(';')
    const text = textArray?.map((el, i) => {
        if (i === textArray.length - 1) return <span key={el} style={{ color: 'blue' }}>{el}</span>
        return (
            <span key={el} style={{ color: 'black' }}>{el}</span>
        )
    }

    )

    const images = imgBox.map((value, index) => {

        return (
            <div key={"img" + index} className={s.picItem} style={device?.img[index] ? { border: "1px solid #e7eaef" } : { border: "1px  #e7eaef" }}>
                <img

                    onClick={() => {
                        setPic(index);
                    }}
                    className={s.picMini}
                    src={"https://premexbel.by/premex/static/" + device?.img[index] + ".jpg"}
                    alt=""
                />
            </div>
        );
    });


    const descr = device?.techcharact.map((value, index) => {
        return (
            <div key={index + "pr "}>
                <div className={s.tech}>
                    <p>{value[0]}</p>
                    <p className={s.techLast}>{value[1]}</p>
                </div>
                <div className={s.line}></div>
            </div>
        );
    });

    const gar = device?.guarant.map((value, index) => {
        return (
            <div key={index + "pr "}>
                <div className={s.tech}>
                    <p>{value[0]}</p>
                    <p>{value[1]}</p>
                </div>
                <div className={s.line}></div>
            </div>
        );
    });
    const doc = device?.documents.map((value, index) => {
        return (
            <div key={index + "pr "}>
                <div className={s.tech}>
                    <p>{value[0]}</p>
                    <p
                        className={s.techPdf}

                    >
                        PDF
                    </p>
                </div>
                <div className={s.line}></div>
            </div>
        );
    });

    let image = "https://premexbel.by/premex/static/" +
        device?.img[actPictures] + ".jpg"

    const close = () => {
        setPopUp(prev => prev.map(() => false))
    }

    return (

        <div>
            {showPattern && <button onClick={onClick}
            
            >Пременить шаблон</button>}
            {(popUp[0] && device) && <Img device={device} updateDev={updateDev} close={close} />}


            <div className={s.product}>


                {dataDeviceupdate.isLoading ? <Preloder /> :
                    <div className={s.container}>

                        <div className={s.block}>

                            <div className={s.blockTop}>
                                <div className={s.pic}>
                                    {buttonPop('img')}
                                    <div className={s.list}>

                                    </div>
                                    <div className={s.picBlock}>
                                        <div className={s.picItems}>{images}</div>

                                        <div className={s.picTop}>
                                            <img src={image} alt="" />
                                        </div>


                                    </div>
                                    <span className={s.textLogo}>
                                        {" "}{buttonPop('producer')}
                                        Производитель {device?.producer}
                                        {popUp[6] && <UIinput style={{ width: "300px" }} value={device?.producer} close={close}
                                            takeInput={(input) => {
                                                if (input && device) updateDev({ ...device, producer: input })
                                            }} />}
                                    </span>
                                </div>
                                <div className={s.title}>
                                    <div className={s.blockTitle}>
                                        <h2>{device?.fullname}</h2>
                                        {buttonPop('fullname')}
                                        {popUp[1] && <>

                                            <UIinput style={{ width: "300px" }} value={device?.fullname} close={close}
                                                takeInput={(input) => {
                                                    if (input && device) updateDev({ ...device, fullname: input })
                                                }} />
                                        </>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className={s.blockBottom}>

                                <div className={s.blockBottomLeft}>
                                    {text}
                                    {buttonPop('text')}
                                    {(popUp[2] && device) && <Text device={device} updateDev={updateDev} close={close} />}
                                   
                                </div>
                                <div className={s.blockBottomRight}>
                                    <div className={s.description}>
                                        {buttonPop('techcharact')}
                                        <h3>Технические характеристики</h3>

                                        <div className={s.descrTech}>{descr}</div>
                                        {(popUp[3] && device) && <TechGar name={'techcharact'} device={device} arrGive={device.techcharact} updateDev={updateDev} close={close} />}
                                    </div>
                                    <div className={s.garant}>

                                        <h3>Гарантия</h3>{buttonPop('guarant')}
                                        <div className={s.descripGarant}>{gar}</div>
                                        {(popUp[5] && device) && <TechGar name={'guarant'} device={device} arrGive={device.guarant} updateDev={updateDev} close={close} />}
                                    </div>
                                    <div className={s.document}>

                                        <h3>Документация</h3>{buttonPop('documents')}
                                        <div className={s.doc}>{doc}</div>
                                        {(popUp[4] && device) && <Docum device={device} arrGive={device.documents} updateDev={updateDev} close={close} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>

        </div>


    );
};

export default Device