import React, { useRef, useState } from 'react'
import s from './pages.module.css'
import Preloder from 'UI/Preloder/Preloder'
import { useDeleteFileMutation, useGetFilesQuery, useUploadMutation } from 'app/redusers/filesApi'
import UIDel from 'components/UI/UIDel'


type FileInputType = {
  name?: string | null
} & Blob

const Files: React.FC = () => {
  const getFilesJ = useGetFilesQuery({ token: localStorage.getItem('tokenAdmin'), ext: 'jpg' });
  const getFilesP = useGetFilesQuery({ token: localStorage.getItem('tokenAdmin'), ext: 'pdf' });
  const [upload] = useUploadMutation();
  const [delFiles] = useDeleteFileMutation();
  const [showFull, setShowFull] = useState<number | null>(null)
  const [choiceList, setChoiceList] = useState<boolean>(true)
  const [showUpload, setShowUpload] = useState<boolean>(false)
  const [fileInp, setFileInp] = useState<FileInputType | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)


  const onClick = (i: number) => {
    if (i === showFull) {
      setShowFull(null)

    } else {
      setShowFull(i)
    }
  }


  const handlSubmit = () => {
    if (inputRef.current) inputRef.current.click();

  }
  const takeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    
    if (e.target.files === null) return


    setFileInp(e.target.files[0])
  }

  const updateFile = async () => {
    if (!fileInp) return

    const file = new FormData();
    if (fileInp) {

    }
    if (choiceList) {
      file.append('img', fileInp)
    } else {
      file.append('pdf', fileInp)
    }


    upload({ file, token: localStorage.getItem('tokenAdmin') })
    setFileInp(null)
  }

  const deleteFile = (name: string | null | undefined) => {


    delFiles({ name, token: localStorage.getItem('tokenAdmin') });

  }


  const files = (extend: string[] | undefined, ext?: 'img') => {

    if (!extend) return <h3>Empty</h3>

    return extend.map((el, i) => {

      return (
        <div key={el + i} className={s.listItem}>
          <div className={s.wrapperDel}>
            <h3 style={ext === 'img' ? { cursor: "pointer" } : {}} onClick={() => { onClick(i) }}>{el}</h3>
            <UIDel style={{margin: "15 0 0 30"}} name={el} del={() => { deleteFile(el) }} size={30} />
          </div>
          {(i === showFull && ext === 'img') && <img style={{ width: "100px" }} src={"https://premexbel.by/premex/static/" + el} alt="" />

          }
        </div>
      )
    })
  }

  return (
    <>
      {(getFilesJ.isLoading || getFilesJ.isLoading) ? <Preloder /> :
        <div className={s.conteiner}>

          <button onClick={() => { setShowUpload(prev => !prev); setFileInp(null) }} style={{ width: "300px" }}>Upload {choiceList ? 'Img' : 'PDF'}</button>

          <div style={showUpload ? { display: "block" } : { display: "none" }}>
            <input ref={inputRef} className={s.uploadInput} type="file" accept={choiceList ? ".jpg" : ".pdf"} onChange={takeFile} />

            <button onClick={handlSubmit}>{choiceList ? 'JPG' : 'PDF'} File</button>
            {fileInp?.size && <button onClick={updateFile}>Send File</button>}
            {fileInp?.name && <h3>{fileInp?.name}</h3>}
          </div>



          <div className={s.header}>

            <h1 onClick={() => { setChoiceList(true) }} style={!choiceList ? { color: "grey", cursor: "pointer" } : { color: "white" }}>{'Files Img :'}</h1>
            <h1 onClick={() => { setChoiceList(false) }} style={choiceList ? { color: "grey", cursor: "pointer" } : { color: "white" }}>{'Files PDF '}</h1>
          </div>

          {choiceList ? <div className={s.list}>
            {files(getFilesJ.data, 'img')}
          </div> : <div className={s.list}>
            {files(getFilesP.data)}
          </div>

          }


        </div>}
    </>
  )
}

export default Files