import React from 'react'
import s from './login.module.css'
import { useNavigate } from 'react-router-dom'


export const Error404: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h1>
        Ошибка
      </h1>
      <button className={s.enter} onClick={() => { navigate('/') }}>Назад</button>
    </div>
  )
}
