import React, { useEffect, useState } from 'react'
import s from './preloder.module.css'
import img from '../../img/Logo_mini.png'


const Preloder = () => {
  const [rotate, setRotate] = useState<number>(0);


  useEffect(() => {
    setInterval(() => {
      setRotate(prev => prev + 10)
    }, 20)
  }, [])

  return (
    <div >
      <img style={{ rotate: `${rotate}deg` }} className={s.pic} src={img} alt="" />
    </div>
  )
}

export default Preloder