
import React, { useState } from 'react'
import { AiFillCheckCircle, AiFillCloseCircle, AiFillPlusCircle } from 'react-icons/ai';
import s from './ui.module.css'
type Props = {
  takeInput: (input: string, index: number | undefined) => void
  size?: number
  index?: number
  text? : string
  children? : React.ReactNode
}

const UIinputCreate: React.FC<Props> = ({ takeInput, size, index, text, children }) => {


  const [input, setInput] = useState<string>('')
  const [showInput, setShowInput] = useState<boolean>(false)


  return (

    <div className={s.conteinerInputC}>
      
      {!showInput ? <div className={s.catalogInput1}>
          {text && <h3>{text}</h3> }
        {children}
      
        <AiFillPlusCircle className={s.icons} size={size} onClick={() => { setShowInput(prev => !prev) }} />
      </div>
            
        : <div className={s.catalogInput2}>
          
          <input type="text" value={input} onChange={(e) => { setInput(e.currentTarget.value) }} />
          <div className={s.conteiner}>
          <AiFillCloseCircle style={{color: "red"}} size={size} className={s.icons} onClick={() => {setShowInput(false)}} />
          <AiFillCheckCircle size={size} className={s.icons} onClick={() => { takeInput(input, index); setInput(''); setShowInput(false) }} />

          </div>
        </div>}
     
      


    </div>


  )
}

export default UIinputCreate