import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { urlServer } from '../../config'
import { IDel, IOrder } from '../interfaces'


export const ordersApi = createApi({
    reducerPath: 'ordersApi',
    baseQuery: fetchBaseQuery({ baseUrl: urlServer + 'basket' }),
    tagTypes: ['Orders'],
    endpoints: (build) => ({
        getOrders: build.query<IOrder[], string | null>({
            query: (token) => ({
                url: '/getorder',
                headers: { Authorization: `${token}` }

            }),
            providesTags: result => ['Orders']

        }),



        delOrders: build.mutation<IOrder, IDel>({
            query: (dOrder) => ({
                url: '/delorder',
                method: 'PUT',
                headers: {Authorization: `${dOrder.token}`},
                body: { i: dOrder.id }

            }),
            invalidatesTags: ['Orders']
        }),



    })
})
export const { useGetOrdersQuery, useDelOrdersMutation } = ordersApi;

