import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { urlServer } from '../../config'
import { IUser, Ilogin } from '../interfaces'

export const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: fetchBaseQuery({ baseUrl: urlServer+'user' }),
    
    endpoints: (build) => ({
        getToken: build.mutation<IUser, Ilogin>({
            query: ({ name, password }) => ({
                url: '/login',
                method: 'POST',
                body: { email: name, password },

            }),
            
        }),

        checkToken: build.query<IUser, string | null>({
            query: (token) => ({
                url: '/auth',
                headers: {Authorization: `${token}`}

            }),
        }),

    })
})
export const { useGetTokenMutation, useCheckTokenQuery } = loginApi;

