import { FC, useState } from "react";
import s from './mainPage.module.css'
import Users from "./Pages/Users";
import Orders from "./Pages/Orders";
import Files from "./Pages/Files";
import Catalog from "./Pages/Catalog";
import Products from "./Pages/Products";


const MainPage:FC = () => {
const buttons : string[] = ['users',  'orders',  'files', 'chat','catalog', 'product'];
const components = [<Users />, <Orders />,  <Files />, <Files />, <Catalog />, <Products />];
const [index, setIndex] = useState<number>(0)
const menuButt = buttons.map((el, i) => {
    if (i === index)  return <button style={{background:"grey"}} onClick={() => setIndex(i)} key={'mB'+el}>{el}</button> 

    return <button  onClick={() => setIndex(i)} key={'mB'+el}>{el}</button>

       
})
    return(
<div className={s.conteiner}>


    <div className={s.main}>
        <div className={s.buttons}>
         {menuButt}   
        </div>
       <div className={s.content}>
       {components[index]}
       </div>
    </div>
        
    </div>

    )
    
}

export {MainPage}