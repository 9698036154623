
import React, { useState } from 'react'
import { AiFillCheckCircle, AiFillCloseCircle, AiFillDelete, AiFillEdit } from 'react-icons/ai';
import s from './ui.module.css'
type Props = {

  setEdit: (name: string, index: number) => void
  del: (index: number) => void
  size?: number
  index: number
  children?: React.ReactNode

}


const UIEdit: React.FC<Props> = ({ setEdit, del, size, index, children }) => {


  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')


  return (
    <>
      {!isEdit ? <div className={s.conteiner}>
        {children}
        <div className={s.conteinerEdit}>
          <AiFillEdit className={s.icons} size={size} onClick={() => { setIsEdit(true) }} />
          <AiFillDelete className={s.icons} size={size} onClick={() => { setIsDelete(true) }} />
        </div>
      </div>
        : <div className={s.catalogInput}>
          <input type="text" value={input} onChange={(e) => { setInput(e.currentTarget.value) }} />
          <div className={s.conteiner}>
            <AiFillCloseCircle style={{ color: "red" }} size={size} className={s.icons} onClick={() => { setIsEdit(false) }} />
            <AiFillCheckCircle className={s.icons} size={size}
              onClick={() => {
                setEdit(input, index); setInput(''); setIsEdit(false)
              }
              } />
          </div>

        </div>
      }


      {isDelete && <div className={s.pullUpDel}>
        <h3>Do you confirm</h3>
        <div className={s.buttons}>

          <button onClick={() => { del(index); setIsDelete(false) }}>Yes</button>
          <button onClick={() => { setIsDelete(false) }}>No</button>
        </div>

      </div>}

    </>

  )
}

export default UIEdit