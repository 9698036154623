
import React, { useState } from 'react'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import s from './ui.module.css'
type Props = {
  takeInput: (input?: string, index?: number ) => void
  close: () => void
  size?: number
  index?: number
  children? : React.ReactNode
  style?: React.CSSProperties
  value?: string
}

const UIinput: React.FC<Props> = ({ takeInput, close, size, index, value, style, children }) => {


  const [input, setInput] = useState(value)
  


  return (

        <div className={s.catalogInput}>  
        
     
            <AiFillCloseCircle style={{color: "red"}} size={size} className={s.icons} onClick={() => {close()}}/>
          <AiFillCheckCircle size={size} className={s.icons} onClick={() => { takeInput(input, index); setInput('') ; close()} } />
    
        <input type="text" style={style} value={input} onChange={(e) => { setInput(e.currentTarget.value) }} />
          
        </div>

  )
}

export default UIinput