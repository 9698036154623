import React, { useState } from 'react'
import s from './products.module.css';
import { IDevice } from 'app/interfaces'
import { AiFillCloseCircle, AiFillDelete, AiFillPlusCircle } from 'react-icons/ai';



type Props = {
    device: IDevice
    updateDev: (changeDevice: IDevice |undefined) => void
    close: () => void
    arrGive: Array<(string|number)[]>
}
type Props2 = {
    arr2: (string | number)[]
    giveInput: (index: number, input: (string | number)[]) => void
    addDel: (index:number, del:0|1) => void
    index: number
}
const Docum: React.FC<Props> = ({ device, updateDev, close, arrGive }) => {

    const [arr, setArr] = useState<Array<(string | number)[]>>(arrGive)

  
  

    const giveInput = (index: number, input: (string | number)[]) => {
        setArr(arr.map((el, i) => {
            if (i === index) return input
            return el
        }))
    }
   const addDel = (index:number, del:0|1) => {
    let a = [...arr]
    if(del === 0) a.splice(index + 1, del, ['', ''])
    a.splice(index, del)
    
    setArr(a)
   }


    const DeviceArray2: React.FC<Props2> = ({ arr2, giveInput, addDel, index }) => {
        const [input, setInput] = useState(arr2)
        const [down, setDown] = useState<boolean[]>([false, false])
        

        return (
            <div className={s.tech}>
                <input type="text"
                    value={input[0]}
                    onChange={(e) => { setInput([e.currentTarget.value, input[1]]) }}
                    onMouseLeave={() => { if (down[0]) { giveInput(index, input) }; setDown([false, false]) }}
                    onFocus={() => setDown([true, false])}
                />
                <input type="text"
                    value={input[1]}
                    onChange={(e) => { setInput([input[0], e.currentTarget.value ]) }}
                    onMouseLeave={() => { if (down[1])  {giveInput(index, input) }; setDown([false, false]) }}
                    onFocus={() => setDown([false, true])}
                />
            <AiFillPlusCircle size={20} onClick={() => {addDel(index, 0)}}/>
            <AiFillDelete size={20} onClick={() => {addDel(index, 1)}}/>
            </div>

        )
    }
console.log(arr);

    const arrDev = arr.map((el, i) => {
        return (
            <div key={'text' + i}>
                <DeviceArray2 arr2={el} giveInput={giveInput} addDel={addDel} index={i}/>
                
            </div>

        )
    })

    return (
        <div className={s.container}>
            <AiFillCloseCircle onClick={close} />
            {arrDev}
            <button onClick={() => {
                updateDev({ ...device, documents: [...arr] })
                
                close()
            }}>
                SAVE</button>
        </div>
    )


};

export default Docum