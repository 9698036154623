import React from 'react';
import './App.css';
import { Login } from './components/Login';
import { Route, Routes } from 'react-router-dom';
import { MainPage } from './components/MainPage';
import { useAppSelector } from 'app/hooks';
import { Error404 } from 'components/Error404';
const App: React.FC = () => {

  const login = useAppSelector(state => state.login)

 
 
  return (
    <div className="App">
      <Routes>
        <Route path='' element={<Login />}></Route>
        {login.user.role !== 'ADMIN' ? <Route path='/main' element={<Error404 />}></Route> :
          <Route path='/main' element={<MainPage />}></Route>
        }
      </Routes>


    </div>
  );
}

export default App;
