import React, { useEffect, useState } from 'react'
import s from './Products/products.module.css'
import { useGetCatalogQuery } from 'app/redusers/catalogApi'
import { ICatalog} from 'app/interfaces'
import Device from './Products/Device'

type Props = {}

const initCat: ICatalog[] = [{
  _id: '',
  catalogName: '',
  underCatalog: [
    { name: '', subCatalog: [['']] }
  ],
  number: 0

}]
const Products: React.FC = (props: Props) => {

  const catalog = useGetCatalogQuery(localStorage.getItem('tokenAdmin'))
  const [currCat, setCurrCat] = useState<number>(0);
  const [currUnd, setcurrUnd] = useState<number>(0);
  const [currSub, setcurrSub] = useState<number>(0);
  const [fullCatalog, setFullCatalog] = useState<(ICatalog[])>(initCat)
  const [currDev, setcurrDev] = useState<string>('');
  const [pattern, setPattern] = useState<string | undefined>()
  const [showPattern, setShowPattern] = useState(false)


  useEffect(() => {

    if (catalog.data) {
      setFullCatalog(catalog.data)
      setcurrDev(
        catalog.data[0].underCatalog[0].subCatalog[0][1]
      )
      if (catalog.data[currCat].underCatalog[0].subCatalog[0][1]) {
        setPattern(catalog.data[currCat].underCatalog[0].subCatalog[0][1])
      } else setPattern(catalog.data[0].underCatalog[0].subCatalog[0][1])
      
    }
  }, [catalog.data, currCat])



  useEffect(() => {
    setcurrDev(
      fullCatalog[currCat].underCatalog[currUnd].subCatalog[currSub][1]
    )
  }, [currCat, currUnd, currSub, fullCatalog])

  useEffect(()=> {
    if (showPattern) {
      setPattern(pattern)
    }
  }, [showPattern, pattern])

  const catalogs = fullCatalog.map((el, i) => {
    return (
      <option key={el._id} value={i}>{el.catalogName}</option>
    )
  })

  const undCatalogs = fullCatalog[currCat].underCatalog.map((el, i) => {
    return (
      <option key={el.name + i} value={i}>{el.name}</option>
    )
  })

  const subCatalogs = fullCatalog[currCat].underCatalog[currUnd].subCatalog.map((el, i) => {
    return (
      <option key={el[0] + i} value={i}>{el[0]}</option>
    )
  })
  const products = fullCatalog[currCat].underCatalog[currUnd].subCatalog[currSub].map((el, i) => {
    if (i === 0) {
      return null
    }
    return (
      <option key={el + i} value={el}>{el}</option>
    )
  })

  return (
    <div>

      <div className={s.menu}>
        <div className={s.menuItem}>
          <h2>Catalog</h2>

          <select onChange={(e) => {
            setCurrCat(+e.currentTarget.value);
            setcurrUnd(0);
            setcurrSub(0);
            setShowPattern(false)
          }} name="cat" id="cat" value={currCat}>{catalogs}</select>
        </div>
        <div className={s.menuItem}>
          <h2>UnderCatalog</h2>

          <select name="cat" id="cat" onChange={(e) => {
            setcurrUnd(+e.currentTarget.value);
            setcurrSub(0);
            setShowPattern(false)
          }}>{undCatalogs}</select>
        </div>
        <div className={s.menuItem}>
          <h2>SubCatalog</h2>

          <select name="cat" id="cat" onChange={(e) => {
            setcurrSub(+e.currentTarget.value);
            setShowPattern(false)
          }}>{subCatalogs}</select>
        </div>
        <div className={s.menuItem}>
          <h2>Products</h2>

          <select name="cat" id="cat" onChange={(e) => {
            
            
            setcurrDev(e.currentTarget.value);
            setShowPattern(false)
          
          }}>{products}</select>
        </div>

      </div>
      <div className={s.menuItem}>
        
        
        {showPattern &&
        <>
          <h2>Pattern</h2>

          <select name="cat" id="cat" onChange={(e) => {

            setPattern(e.currentTarget.value)
          }}>
            <option></option>
            {products}
            </select>

        </>}
        <div className={s.check}>
          <h2>add pattern</h2>
          <input onChange={(e) => {
            setShowPattern(e.currentTarget.checked)
          }} type="checkbox"  checked={showPattern}/>
        </div>

      </div>

      {(currDev && currDev.length > 0) && <Device name={currDev} pattern={pattern} showPattern={showPattern} 
      setShowPattern={setShowPattern} />}
    </div>

  )
}

export default Products