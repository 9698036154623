import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { urlServer } from '../../config'
import { ICatalog, IDevice, INewCatalog, IUnderCatalog } from '../interfaces'


type dataTape = {
    catalog: INewCatalog[]
    token: string | null
}
type dataTape2 = {
    id : string
    number: number
    catalogName: string
    underCatalog: IUnderCatalog[]
    token: string | null
}
type dataTape3 = {
    name: string
    catalog: string
    underCatalog: string
    subCatalog: string
    token: string | null
}
type dataTape4 = {
    name: string
    token: string | null
}

export const catalogApi = createApi({
    reducerPath: 'catalogApi',
    baseQuery: fetchBaseQuery({ baseUrl: urlServer + 'catalog' }),
    tagTypes: ['Catalog'],
    endpoints: (build) => ({
        changeList: build.mutation<ICatalog[], dataTape>({
            query: ({ catalog, token }) => ({
                url: '/change',
                method: 'POST',
                body: catalog,
                headers: { Authorization: `${token}` }
            }),
            invalidatesTags: ['Catalog']
        }),
        changeCatalog: build.mutation<ICatalog[], dataTape2>({
            query: ({id, number, catalogName, underCatalog, token }) => ({
                url: '/update',
                method: 'POST',
                body: {id, number, catalogName, underCatalog },
                headers: { Authorization: `${token}` }
            }),
            invalidatesTags: ['Catalog']
        }),

        getCatalog: build.query<ICatalog[], string | null>({
            query: (token) => ({
                url: '/getAll',
                headers: { Authorization: `${token}` }

            }),
            providesTags: result => ['Catalog']

        }),

        createDevice: build.mutation<IDevice, dataTape3>({
            query: ({ name, catalog, underCatalog, subCatalog, token }) => ({
                url: '/createDevice',
                method: 'POST',
                body: { name, catalog, underCatalog, subCatalog },
                headers: { Authorization: `${token}` }
            }),
            invalidatesTags: ['Catalog']
        }),
        deleteDevice: build.mutation<IDevice[], dataTape4>({
            query: ({ name, token }) => ({
                url: '/deleteDevice',
                method: 'DELETE',
                body: { name },
                headers: { Authorization: `${token}` }
            }),
            invalidatesTags: ['Catalog']
        }),


    })
})
export const { useGetCatalogQuery, useChangeListMutation, useChangeCatalogMutation,
    useCreateDeviceMutation, useDeleteDeviceMutation } = catalogApi;

