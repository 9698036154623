import React, { useEffect, useState } from 'react'
import s from './sub.module.css'
import { ICatalog, IUnderCatalog } from 'app/interfaces';
import { AiFillCloseCircle } from 'react-icons/ai';
import UIinputCreate from 'components/UI/UIinputCreate';
import UIEdit from 'components/UI/UIEdit';
import Product from './Product';


interface IProps {
    catalog: ICatalog
    index: number
    changeUnderCat: (index: number, underCatalog: IUnderCatalog[]) => void
    close: () => void

}

const SubCatalog: React.FC<IProps> = ({ catalog, index, changeUnderCat, close }) => {


    const [fullCatalog, setFullCatalog] = useState<ICatalog>(catalog)
    const [currentUnd, setCurrentUnd] = useState<number>(0)
    const [currentSub, setCurrentSub] = useState<number>(0)

    useEffect(() => {
        setFullCatalog({ ...catalog })

    }, [catalog])

    const subCatSelect = fullCatalog.underCatalog.map((el, i) => {

        return (
            <option key={'' + el + i} value={i}>{el.name} </option>
        )
    })
    const options = fullCatalog.underCatalog[currentUnd].subCatalog.map((el, i) => {

        return (
            <option key={'op' + el + i} value={i}>{el[0]} </option>
        )
    })


    const addSub = (name: string, i: number | undefined) => {

        let a = catalog.underCatalog.map((el: IUnderCatalog, k) => {
            
            
            if (k === currentUnd) {
                if (currentSub === 0 && el.subCatalog[0][0] === '') {
                    let b = [...el.subCatalog[0]]
                    b.splice(0, 1, name)
                    
                   return {...el, subCatalog: [b] } 
                }
                return { ...el, subCatalog: [...el.subCatalog, [name]] }
            
            }

            return el
        })




        changeUnderCat(index, a)


    }

    const editSubCatalog = (name: string, index2: number | undefined) => {

        const editedSub = fullCatalog.underCatalog[currentUnd].subCatalog[currentSub].map((el, i) => {
            if (i === 0) return name
            return el
        })
        const editUnd = fullCatalog.underCatalog[currentUnd].subCatalog.map((el, i) => {
            if (i === currentSub) return editedSub
            return el
        })
        const a = fullCatalog.underCatalog.map((el, i) => {
            if (i === currentUnd) return { ...el, subCatalog: editUnd }
            return el
        })


        changeUnderCat(index, a)



    }

    const deleteSubCatalog = (index2: number) => {

        if (catalog.underCatalog[currentUnd].subCatalog.length < 2) {
            let newUnd = catalog.underCatalog.map((el, i) => {
                if (i !== currentUnd) return el
                return { ...el, subCatalog: [['']] }

            })

            changeUnderCat(index, newUnd);
        } else {
            let a: IUnderCatalog[] = [];
            let b = [...catalog.underCatalog[currentUnd].subCatalog]
            b.splice(index2, 1)
            catalog.underCatalog.forEach((el, i) => {
                if (i !== currentUnd) a = [...a, el]
                else {
                    a = [...a, { ...el, subCatalog: b }]
                }

            })



            changeUnderCat(index, a);


        }


    }

    return (
        <div className={s.conteinerSub}>

            <AiFillCloseCircle size={40} className={s.subClose} onClick={close} />
            <div className={s.menu}>
                <h2>{catalog.catalogName}</h2>

                {(catalog.underCatalog.length > 1 || catalog.underCatalog[0].name) && <select name="und" id="und" onChange={(e) => {
                    setCurrentUnd(+e.currentTarget.value)
                    setCurrentSub(0)
                }
                }>{subCatSelect}</select>}
            </div>
            <div className={s.catalog}>
                <h2>SubCatalog</h2>

                {(catalog.underCatalog[0].name) &&
                    <>

                        {(catalog.underCatalog[currentUnd].subCatalog[currentSub][0] !== '') &&
                            <div className={s.flexWrapper}>

                               

                                <UIEdit del={deleteSubCatalog} setEdit={editSubCatalog} size={20} index={currentSub} >
                                     <select
                                    name="sub"
                                    value={String(currentSub)}
                                    id="sub" onChange={(e) => {
                                        setCurrentSub(+e.currentTarget.value)

                                    }
                                    }>{options}</select>
                                    </UIEdit>
                            </div>}


                        <div className={s.flexWrapper}>

                            <UIinputCreate text='ADD SubCatalog' size={30} index={index} takeInput={addSub} />
                        </div>
                    </>
                }
            </div>
            <div className={s.products}>
                <h2 >Products</h2>
                <div className={s.flexWrapper}>
                    <Product catalog={catalog} indexCat={index} index1={currentUnd} index2={currentSub} changeUnderCat={changeUnderCat} />
                </div>

            </div>
        </div>
    )
}

export default SubCatalog