import { ICatalog, IUnderCatalog } from 'app/interfaces'
import { useCreateDeviceMutation, useDeleteDeviceMutation } from 'app/redusers/catalogApi'
import UIEdit from 'components/UI/UIEdit'
import UIinputCreate from 'components/UI/UIinputCreate'
import React, { useEffect, useState } from 'react'

type Props = {
  catalog: ICatalog
  indexCat: number
  index1: number
  index2: number
  changeUnderCat: (index: number, underCatalog: IUnderCatalog[]) => void
}

const Product: React.FC<Props> = ({ catalog, indexCat, index1, index2, changeUnderCat }) => {

  const [currentProd, setCurrProd] = useState<number>(0)
  const [product, setProduct] = useState<string[]>(catalog.underCatalog[index1].subCatalog[index2])
  const [createDevice] = useCreateDeviceMutation();
  const [deleteDevice] = useDeleteDeviceMutation();


  useEffect(() => {
    setProduct(catalog.underCatalog[index1].subCatalog[index2])
  }, [catalog, index1, index2])

  const onDragStartHandler = (e: React.DragEvent<HTMLDivElement>, i: number) => {
    setCurrProd(i)

  }
  const onDragOver = (e: React.DragEvent<HTMLDivElement>, i: number) => {
    e.preventDefault()

  }


  const onDropHandler = (e: React.DragEvent<HTMLDivElement>, i: number) => {
    e.preventDefault()
    let a = product.map((el, index) => {
      if (index === currentProd) return product[i]
      if (index === i) return product[currentProd]
      return el
    })
    setProduct(a)
    let newSUb = catalog.underCatalog[index1].subCatalog.map((el, i) => {
      if (i === index2) return a
      return el
    })
    let newUnd = catalog.underCatalog.map((el, i) => {
      if (i === index1) return { ...el, subCatalog: newSUb }
      return el
    })
    changeUnderCat(indexCat, newUnd)

  }
  const takeInput = (input: string, index: number | undefined): void => {
    let newSub = catalog.underCatalog[index1].subCatalog.map((el, i) => {
      if (i === index2) return [...el, input]
      return el
    })
    let a = catalog.underCatalog.map((el, i) => {
      if (i === index1) return { ...el, subCatalog: newSub }
      return el
    })
    createDevice({
      name: input, catalog: catalog.catalogName,
      underCatalog: catalog.underCatalog[index1].name, subCatalog: newSub[index2][0],
      token: localStorage.getItem('tokenAdmin')
    })

    changeUnderCat(indexCat, a)
  }
  const edit = (name: string, index: number) => {
    let b = catalog.underCatalog[index1].subCatalog[index2].map((el, i) => {
      if (i === index) return name
      return el
    })
    let newSub = catalog.underCatalog[index1].subCatalog.map((el, i) => {
      if (i === index2) return b
      return el
    })
    let a = catalog.underCatalog.map((el, i) => {
      if (i === index1) return { ...el, subCatalog: newSub }
      return el
    })

    changeUnderCat(indexCat, a)

  }
  const del = (index: number) => {

    let b = [...catalog.underCatalog[index1].subCatalog[index2]]
    let device = b.splice(index, 1)
    let newSub = catalog.underCatalog[index1].subCatalog.map((el, i) => {
      if (i === index2) return b
      return el
    })
    let a = catalog.underCatalog.map((el, i) => {
      if (i === index1) return { ...el, subCatalog: newSub }
      return el
    })

    deleteDevice({ name: device[0], token: localStorage.getItem('tokenAdmin') })

    changeUnderCat(indexCat, a)
  }

  const products = product.map((el, i) => {
    if (i === 0) return <div key={el + 'pr' + i}></div>
    return (
      <div style={{ cursor: "grab", display: "flex" }} key={el + 'pr' + i}
        draggable
        onDragStart={(e) => { onDragStartHandler(e, i) }}
        onDragOver={(e) => { onDragOver(e, i) }}
        onDrop={(e) => { onDropHandler(e, i) }}

      >

        <UIEdit setEdit={edit} del={del} index={i} >
          <h3>{el}</h3>
        </UIEdit>

      </div>

    )

  })
  return (
    <div style={{ marginTop: "-20px" }}>
      {products}
      <UIinputCreate takeInput={takeInput} index={product.length} size={30}>
        <h3>ADD Product</h3>
      </UIinputCreate>

    </div>
  )
}

export default Product