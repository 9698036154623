import { IUser } from './../interfaces';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: IUser = {
    user: {
        email: '',
        token: '',
        role: '',
    },


}
export const loginSlice = createSlice({

    name: 'login',
    initialState,
    reducers: {
        tokenReduser: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload.user
        },

    }

})
export const { tokenReduser } = loginSlice.actions;
export default loginSlice.reducer;

