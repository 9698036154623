
import React, {  CSSProperties, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai';
import s from './ui.module.css'
type Props = {
    
    
    del: (name: string|number) => void
    size?: number
    name : string|number
    style?: CSSProperties

}


const UIDel: React.FC<Props> = ({del, size, name, style}) => {
    

    const [isDelete, setIsDelete] = useState<boolean>(false)

  return (
    <>
      <div className={s.conteiner}>
       
        <AiFillDelete style={{...style, cursor:"pointer" }} size={size}  onClick = {() => {setIsDelete(true)}}/>
    </div>
    {isDelete && <div className={s.pullUpDel}>
                <h4>Do you confirm</h4>
                <div className={s.buttons}>
                <button onClick={() => {del(name); setIsDelete(false)}}>Yes</button>
                <button onClick={() => {setIsDelete(false)}}>No</button>
                </div>
            
            </div>}
    </>
  
  )
}

export default  UIDel