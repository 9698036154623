import React, { useState } from 'react'
import s from './pages.module.css'
import { useDelUsersMutation, useGetUsersQuery } from 'app/redusers/usersAPI'
import Preloder from 'UI/Preloder/Preloder'
import UIDel from 'components/UI/UIDel'

const Users: React.FC = () => {
  const getUsers = useGetUsersQuery(localStorage.getItem('tokenAdmin'));
  const [delUser] = useDelUsersMutation();
  const [showFull, setShowFull] = useState<number | null>(null)

  const onClick = (i: number) => {
    if (i === showFull) {
      setShowFull(null)

    } else {
      setShowFull(i)
    }
  }

  const deleteUser = (email: string) => {
  
    delUser({email, token: localStorage.getItem('tokenAdmin')});

  }



  const users = getUsers.data?.users.map((el, i) => {


    return (
      <div key={el._id} className={s.listItem}>
        <div className={s.wrapperDel}>
          <h3 style={{ cursor: "pointer" }} onClick={() => { onClick(i) }}>{`${el.email} (${el.company})`}
          </h3> {i !== 0 && <UIDel style={{margin: "15 0 0 30"}} name={el.email} del={() => { deleteUser(el.email) }} size={30} />}
        </div>
        {i === showFull && <><h3>{`tel: ${el.tel}`}</h3>
          <h3>{`unp: ${el.tel}`}</h3></>
        }
      </div>
    )
  })
  return (
    <div className={s.conteiner}>
      <h1>{'Users :'}</h1>
      {getUsers.isLoading ? <Preloder /> :
        <div className={s.list}>
          {users}
        </div>}
    </div>
  )
}

export default Users