import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { urlServer } from '../../config'
import { IFile, IFileList } from '../interfaces'



export const filesApi = createApi({
    reducerPath: 'filesApi',
    baseQuery: fetchBaseQuery({ baseUrl: urlServer + 'files' }),
    tagTypes: ['Files'],
    endpoints: (build) => ({
        getFiles: build.query<string[], IFileList>({
            query: ({token, ext}) => ({
                url: '/getall/'+ext,
                headers: { Authorization: `${token}` },
                
            }),
            providesTags: result => ['Files']

        }),



        upload: build.mutation<IFileList, IFile>({

            query: ({file, token}) => ({
                url: '/upload',
                method: 'POST',
                headers: { Authorization: `${token}` },
                body: file,

            }),
            invalidatesTags: ['Files']
        }),
        deleteFile: build.mutation<IFileList, IFileList>({

            query: ({name, token}) => ({
                url: '/delete',
                method: 'DELETE',
                headers: { Authorization: `${token}` },
                body: {name},

            }),
            invalidatesTags: ['Files']
        }),


    })
})
export const { useGetFilesQuery, useUploadMutation, useDeleteFileMutation } = filesApi;

