import React, { FC, useEffect, useState } from 'react'
import s from './login.module.css'
import { Ilogin } from '../app/interfaces';
import { useCheckTokenQuery, useGetTokenMutation } from '../app/redusers/loginApi';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { tokenReduser } from 'app/redusers/loginSlice';
import Preloder from 'UI/Preloder/Preloder';





const Login: FC = () => {
  let token: string | null = localStorage.getItem('tokenAdmin')
  const [inp, setInp] = useState(['', '']);
  const [error, setError] = useState('')
  const [giveLogin, { data, isLoading }] = useGetTokenMutation();
  const getToken = useCheckTokenQuery(token);
  const navigate = useNavigate();
  const login = useAppSelector(state => state.login)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (getToken.data?.user) {

      dispatch(tokenReduser({
        user: {
          email: getToken.data.user.email, token: '',
          role: getToken.data.user.role
        }
      }))
    }

  }, [dispatch, getToken.data?.user])
  const close = () => {
    localStorage.setItem('tokenAdmin', '');
    dispatch(tokenReduser({ user: { email: '', token: '', role: '' } }))
  }


  const onClick = async () => {

    await giveLogin({ name: inp[0], password: inp[1] } as Ilogin).unwrap()
      .catch((er) => { setError(er.data.message) });;

    setInp(['', '']);

  }

  if (data?.user.token) localStorage.setItem('tokenAdmin', data?.user.token)


  return (
    <>

      {(isLoading || getToken.isLoading) ? <Preloder /> :
        <div className={s.conteiner}>

          <div className={s.main}>

            {error ? <h2>{error}</h2> : <h2>{login.user.email}</h2>}

            <input name='name' value={inp[0]} placeholder='login' onChange={(e) => {
              setInp([e.target.value, inp[1]]);
            }} />
            <input type='password' name='password' value={inp[1]} placeholder='password' onChange={(e) => { setInp([inp[0], e.target.value]) }} />
            <button onClick={onClick}>Login</button>
            {getToken.data?.user.role === 'ADMIN' && <button className={s.enter} onClick={() => { navigate('/main') }}>ENTER</button>}
          </div>
          <div onClick={close} className={s.close}>X</div>

        </div>
      }
    </>
  )
}

export { Login }
