import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { urlServer } from '../../config'
import { IDevice} from '../interfaces'

type TDevice = {
    token: string | null
    device: IDevice
}

export const deviceApi = createApi({
    reducerPath: 'deviceApi',
    baseQuery: fetchBaseQuery({ baseUrl: urlServer + 'device' }),
    tagTypes: ['Device'],
    endpoints: (build) => ({
      
        getDevice: build.mutation<IDevice, string>({
            query: (name) => ({
                url: `/getone`,
                method: 'POST',
                body: {name}
                
            }),
            
            invalidatesTags: ['Device']
        }),
        getDevice2: build.mutation<IDevice, string>({
            query: (name) => ({
                url: `/getone`,
                method: 'POST',
                body: {name}
                
            }),
            
            
        }),
        updateDevice: build.mutation<TDevice, TDevice>({
            query: ({ device, token }) => ({
                url: '/update',
                method: 'POST',
                body: { device },
                headers: { Authorization: `${token}` }
            }),
            invalidatesTags: ['Device']
        }),




    })
})
export const { useGetDeviceMutation, useGetDevice2Mutation, useUpdateDeviceMutation } = deviceApi;

