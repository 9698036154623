import React, { useEffect, useState } from 'react'
import s from './products.module.css';
import style from './img.module.css';
import { IDevice } from 'app/interfaces'
import { AiFillCaretLeft, AiFillCaretRight, AiFillCloseCircle } from 'react-icons/ai';
import { useGetFilesQuery } from 'app/redusers/filesApi';


type Props = {
    device: IDevice
    updateDev: (changeDevice: IDevice | undefined) => void
    close: () => void
}

const Img: React.FC<Props> = ({ device, close, updateDev }) => {


    const getFilesJ = useGetFilesQuery({ token: localStorage.getItem('tokenAdmin'), ext: 'jpg' });
    const [actPictures, setPic] = useState('');
    const [imgBox, setImgBox] = useState<string[]>(['', '', ''])
    const [currImg, setCurrImg] = useState<string>('')
    const [n, setN] = useState<number>(0)

    useEffect(() => {
        if (device) setImgBox(device.img)
    }, [device])

    const onDragStartHandler = (a: string) => {

        setCurrImg(a);

    }
    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()

    }

    const onDropHandler = (e: React.DragEvent<HTMLDivElement>, i: number) => {
        e.preventDefault()
        let a = imgBox.map((el: string, index) => {
            if (i === index) return currImg
            return el
        })

        setImgBox(a)

    }


    const files = getFilesJ.data?.map((el, i) => {
        let a = el.split('.')[0]
        return (
            <h3
                draggable
                onDrag={() => { onDragStartHandler(a) }}
                onMouseOver={() => setPic(a)}
                style={{ color: "white" }}
                key={el}>
                {a}
            </h3>
        )
    })


    const bitFiles = (s: number) => {

        let a: JSX.Element[] | undefined = []
        let b = s + 15
        for (s; s < b; s++) {
            if (files) a = [...a, files[s]]

        }

        return a
    }


    const images = imgBox.map((value, index) => {

        return (
            <div key={"img" + index}

                onDragOver={(e) => { onDragOver(e) }}
                onDrop={(e) => { onDropHandler(e, index) }}
                className={s.picItem} style={{ border: "2px solid black" }}>
                <AiFillCloseCircle size={50} onClick={() => {
                    setImgBox(imgBox.map((el, i) => {
                        if (i === index) return ''
                        return el
                    }))
                }} />
                <img

                    className={s.picMini}
                    src={"https://premexbel.by/premex/static/" + value + ".jpg"}
                    alt=""
                />
            </div>
        );
    });




    let image = "https://premexbel.by/premex/static/" +
        actPictures + ".jpg"
    return (
        <div className={style.conteiner} >
            <div className={style.list}>
                {bitFiles(n)}

                {n > 0 && <AiFillCaretLeft cursor="pointer" color='white' size={30} onClick={() => { setN(prev => prev - 15) }} />}
                {(files && n < files?.length - 15) && <AiFillCaretRight cursor="pointer" color='white' size={30} onClick={() => { setN(prev => prev + 15) }} />}

            </div>

            <div className={s.product}>
                <AiFillCloseCircle size={30} style={{ color: "red", marginLeft: "90%" }} onClick={close} />
                <div className={s.container}>

                    <div className={s.block}>
                        <div className={s.blockTop}>
                            <div className={s.pic}>

                                <div className={s.list}>

                                </div>
                                <div className={s.picBlock}>
                                    <div className={s.picItems}>{images}</div>

                                    <div className={style.picBig}>
                                        <img src={image} alt="" />
                                    </div>

                                </div>
                                <button onClick={() => {
                                    if (imgBox) updateDev({ ...device, img: imgBox });
                                    close()
                                }}>SAVE</button>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    );
};

export default Img