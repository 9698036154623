import React, { useEffect, useState } from 'react'
import s from './catalog.module.css'
import { useChangeCatalogMutation, useChangeListMutation, useGetCatalogQuery } from 'app/redusers/catalogApi'
import { ICatalog, INewCatalog, IUnderCatalog } from 'app/interfaces';
import UnderCatalog from './UnderCatalog';
import SubCatalog from './SubCatalog';
import UIEdit from 'components/UI/UIEdit';
import UIinputCreate from 'components/UI/UIinputCreate';


const Catalog: React.FC = () => {



    const getCatalog = useGetCatalogQuery(localStorage.getItem('tokenAdmin'));
    const [changeCatalogList] = useChangeListMutation();
    const [changeCatalog] = useChangeCatalogMutation();
    const [fullCatalog, setFullCatalog] = useState<(ICatalog[])>([]);
    const [showSubCut, setShowSubCat] = useState<boolean>(false)
    const [indexSub, setIndexSub] = useState(0)


    useEffect(() => {
        if (getCatalog.data) {
            setFullCatalog(getCatalog.data.map(el => el))


        }
    }, [getCatalog.data])






    const onChange = (e: React.ChangeEvent<HTMLSelectElement>, i: number) => {
        let listName = fullCatalog.map(el => el.catalogName)
        let n = listName.indexOf(e.currentTarget.value);

        if (!e.currentTarget.value) return
        let list = fullCatalog.map((el, index) => {


            if (index === n) return { ...fullCatalog[i], number: n }

            if (i === index) return { ...fullCatalog[n], number: i }
            return el
        })

        let a: INewCatalog[] = list.map(el => ({
            catalogName: el.catalogName,
            number: el.number, underCatalog: el.underCatalog
        }))


        changeCatalogList({ catalog: a, token: localStorage.getItem('tokenAdmin') })

    }

    const changeUnderCat = (index: number, underCatalog: IUnderCatalog[]) => {

        changeCatalog({
            id: fullCatalog[index]._id, number: index, catalogName: fullCatalog[index].catalogName,
            underCatalog, token: localStorage.getItem('tokenAdmin')
        })



    }
    const addCatalog = (input: string, index: number | undefined) => {

        let a: INewCatalog[] = fullCatalog.map(el => ({
            catalogName: el.catalogName,
            number: el.number, underCatalog: el.underCatalog
        }))

        changeCatalogList({
            catalog: [...a, {
                catalogName: input, number: a.length, underCatalog: [
                    { name: '', subCatalog: [['']] }
                ]
            }], token: localStorage.getItem('tokenAdmin')
        })

    }
    const deleteCatalog = (index: number) => {


        let a: INewCatalog[] = []
        fullCatalog.forEach((el, i) => {
            if (i !== index) a = [...a, {
                catalogName: el.catalogName,
                number: el.number, underCatalog: el.underCatalog
            }]
        })
        changeCatalogList({
            catalog: a, token: localStorage.getItem('tokenAdmin')
        })

    }

    const editCatalog = (name: string, index: number) => {

        let a = fullCatalog.map((el, i) => {
            if (i === index) return { ...el, catalogName: name }
            return el
        })



        setFullCatalog(a)
        changeCatalog({
            id: a[index]._id, number: index, catalogName: a[index].catalogName,
            underCatalog: a[index].underCatalog, token: localStorage.getItem('tokenAdmin')
        })
    }

    const options = fullCatalog.map((el, i) => {

        return (
            <option key={'op' + el + i} value={el.catalogName}>{el.catalogName}</option>
        )
    })




    const catalog = fullCatalog.map((el, i: number) => {

        return (
            <div key={'' + el + i} className={s.listItem}>
                <div className={s.wrapper}>

                    <div className={s.selectCatalog}>


                        <select onChange={(e) => {
                            onChange(e, i);
                        }} value={el.catalogName}>
                            {options}
                        </select>


                        <UIEdit index={i} setEdit={editCatalog} del={deleteCatalog} size={20} >
                            <h2>{el.catalogName}</h2>
                        </UIEdit>
                    </div>

                    {(fullCatalog[i].underCatalog && fullCatalog[i].underCatalog.length > 0) ?
                        <UnderCatalog underCatalog={fullCatalog[i].underCatalog} index={i}
                            changeUnderCat={changeUnderCat}

                        /> : <UnderCatalog underCatalog={[{ name: '', subCatalog: [['']] }]} index={i}
                            changeUnderCat={changeUnderCat}

                        />}

                    <button className={s.btn} style={{ width: '130px', fontSize: '16px', height: '40px' }} onClick={() => { setIndexSub(i); setShowSubCat(true) }}>SubCatalog</button>



                </div>

            </div>

        )
    })

    return (
        <div className={s.conteiner}>
            {!showSubCut ? <>

                <div className={s.wrapperTitle}>
                    <UIinputCreate takeInput={addCatalog} size={20} >
                        <h2>ADD New Catalog</h2>
                    </UIinputCreate>
                </div>
                {catalog}

            </> :

                <SubCatalog catalog={fullCatalog[indexSub]} index={indexSub}
                    changeUnderCat={changeUnderCat} close={() => { setShowSubCat(false) }} />




            }




        </div>
    )
}


export default Catalog