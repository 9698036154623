import React, { useState } from 'react'
import s from './pages.module.css'
import { AiOutlineReload} from 'react-icons/ai'
import Preloder from 'UI/Preloder/Preloder'
import { useDelOrdersMutation, useGetOrdersQuery } from 'app/redusers/ordersApi'
import { IBasket } from 'app/interfaces'
import UIDel from 'components/UI/UIDel'

const Orders: React.FC = () => {
  const getOrders = useGetOrdersQuery(localStorage.getItem('tokenAdmin'));
  const [delOrders] = useDelOrdersMutation();
  const [showFull, setShowFull] = useState<number | null>(null)
  
  const onClick = (i: number) => {
    if (i === showFull) {
      setShowFull(null)

    } else {
      setShowFull(i)
    }
  }

  const deleteOrder = (id: string) => {


    delOrders({id, token: localStorage.getItem('tokenAdmin')});

  }

  const baskets = (basket: IBasket[]) => {
    return basket.map((el, i) => {
      return (
        <h3 key={i + 'bas'}>
          {`${el[0]} : ${el[1]} шт.`}
        </h3>
      )
    })
  }



  const orders = getOrders.data?.map((el, i) => {


    return (
      <div key={el.time + i} className={s.listItem}>
        <div className={s.wrapperDel}>
          <h3 style={{ cursor: "pointer" }} onClick={() => { onClick(i) }}>{`${el.order.email} (${el.order.company})`}
          </h3> <UIDel style={{margin: "15 0 0 30"}} name={el.id} del={() => { deleteOrder(el.id)}} size={30}  />
        </div>
        {i === showFull && 
        <>
        <h3>{`date: ${el.time}`}</h3>
        <h3>{`tel: ${el.order.tel}`}</h3>
          <h3>{`unp: ${el.order.unp}`}</h3>
          {baskets(el.basket)}
        </>
        }
      </div>
    )
  })
  return (
    <div className={s.conteiner}>
      <div className={s.header}>
        <h1>{'Orders :'}</h1>
        <AiOutlineReload style={{cursor:"pointer"}} size={'32px'} onClick={() => {getOrders.refetch()}}/>
      </div>
      
      {getOrders.isLoading ? <Preloder /> :
        <div className={s.list}>
          {orders}
        </div>}
    </div>
  )
}

export default Orders