import React, { useEffect, useState } from 'react'
import s from './pages.module.css'
import { IUnderCatalog } from 'app/interfaces';
import UIinputCreate from 'components/UI/UIinputCreate';
import UIEdit from 'components/UI/UIEdit';


interface IProps {
    underCatalog: IUnderCatalog[]
    index: number
    changeUnderCat: (index: number, underCatalog: IUnderCatalog[]) => void
}

const UnderCatalog: React.FC<IProps> = (props) => {


    const [undCatalogList, setUndCatalogList] = useState<IUnderCatalog[]>(props.underCatalog);



    useEffect(() => {
        setUndCatalogList(props.underCatalog)
    }, [props.underCatalog])

    const editCatalog = (name: string, index: number | undefined) => {


        let a = undCatalogList.map((el: IUnderCatalog, i: number) => {
            if (i === index) return { ...el, name }
            return el
        })
        if (index === a.length) a = [...a, { name, subCatalog: [['']] }]
        if (a.length > 0) {
            setUndCatalogList(a)
            props.changeUnderCat(props.index, a)
        }
    }
    const deleteCatalog = (index: number) => {
        let a = [...undCatalogList]
        if (undCatalogList.length > 1) {
            a.splice(index, 1)
        } else a = [{ name: '', subCatalog: [['']] }];

        setUndCatalogList(a)
        props.changeUnderCat(props.index, a)
    }


    const underCatalog = undCatalogList.map((el, i: number) => {

        return (

            <div className={s.wrapperDelete} key={'' + el + i}>


                <div className={s.selectUnderCatalog}>
                    {(el.name?.length > 0) ?
                        <div className={s.wrapperDel1}>

                            <UIEdit del={deleteCatalog} setEdit={editCatalog} index={i}>
                                <h4>{el?.name}</h4>
                            </UIEdit>
                        </div>
                        :
                        <div className={s.wrapperDel2}>

                            <UIinputCreate size={20} index={i} takeInput={editCatalog}>
                                <h3>ADD UnderCatalog</h3>
                            </UIinputCreate>
                        </div>

                    }

                </div>



            </div>



        )
    })

    return (
        <div className={s.conteinerUnder}>



            <div className={s.wrapperFlex}>

                <div className={s.wrapperFlexItem}>{underCatalog}
                </div>
                {(undCatalogList[0].name.length > 0) && <UIinputCreate size={20} index={undCatalogList.length} takeInput={editCatalog}><h3>ADD UnderCatalog</h3></UIinputCreate>}


            </div>



        </div>
    )
}

export default UnderCatalog