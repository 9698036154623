import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { urlServer } from '../../config'
import { IUsers, IDel } from '../interfaces'



export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl: urlServer+'user' }),
    tagTypes: ['Users'],
    endpoints: (build) => ({
        delUsers: build.mutation<IUsers, IDel>({
            query: ( dUser: IDel) => ({
                url: '/del',
                method: 'DELETE',
                body: { email: dUser.email },
                headers: {Authorization: `${dUser.token}`}
            }),
            invalidatesTags: ['Users']
        }),

        getUsers: build.query<IUsers, string | null>({
            query: (token) => ({
                url: '/getAll',
                headers: {Authorization: `${token}`}

            }),
            providesTags: result => ['Users']

        }),

    })
})
export const { useGetUsersQuery, useDelUsersMutation } = userApi;

